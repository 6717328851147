<template>
  <div class="category min_main">
    <div class="page_width">
        <div class="category_title">{{ title }}</div>
        <div class="category_container grid grid_min_gab" >
            <div class="category_thumb grid_md_3 grid_xs_4 grid_sm_6" v-for="(item,index) in categoryList" :key="index" @click="toPage('details',item.offer_id)">
                <div class="thumb_tab pointer">
                    <div class="tab_img" v-if="item.gallery_images">
                        <img :src="item.gallery_images[0]" alt="">
                    </div>
                    <div class="tab_name flex_content_start">
                        <img src="	https://www.g2g.com/img/region-flags/global.svg" alt="" style="width: 12px;height: 12px;margin-right:4px;">
                        {{ item.title }}
                     </div>
                    <div class="tab_msg_group flex_sb">
                        <div class="msg_offers">{{ item.total_offer }} offers</div>
                        <div class="msg_price">from <span>{{ item.converted_unit_price }}</span> usd</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    
  </div>
</template>

<script>
export default {
  name: 'G2GCategories',

  data() {
    return {
        seo_term:'',
        title:'',
        categoryList:[]
    };
  },
  created(){
    this.seo_term = this.$route.query.seo_term
    this.title = this.$route.query.title
    this.getData()
  },

  mounted() {
    
  },

  methods: {
    toPage(e,id){
        this.$router.push('/'+e+'?offer_id='+id)
    },
    getData(){
        const loading = this.$loading({
            lock: true,
            text: 'Loading',
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.7)'
        });
        let seo_term = this.seo_term
        this.$axios({
            url:'https://sls.g2g.com/offer/search?seo_term='+seo_term+'&page_size=48&currency=USD&country=CN',
            method:'get',
            data:{},
            headers: {
                'Content-type':'application/json;charset=utf-8 '
            }
        }).then(res=>{
            let result = res.data.payload.results
            console.log('result',result)
            // let list = result.map(v=>{
            
            // v.brand_name = this.categories[v.brand_id].en
            // return v
            // })
            this.categoryList = result
            loading.close()
        }).catch(()=>{
            loading.close()
        })
      },
  },
};
</script>
<style lang="scss" scoped>
.category{
    .category_title{
        padding:48px 0 24px;
        color:#303030;
        font-size:24px;
        text-align: center;
        font-weight: 600;
    }
    .category_thumb{
        border-radius: 10px;
        border:1px solid rgba(0,0,0,.12);
        // min-height: 266px;
        top: 0;
        transition: all 0.2s ease-out;
        position: relative;
        padding:16px;
        background:#fff;
        &:hover{
            top:-5px;
            box-shadow: 0 5px 7px rgba(0, 0, 0, 0.18);
        }
        .tab_img{
            height: 150px;
            >img{
                object-fit: contain;
            }
        }
        .tab_name{
            padding:16px 0 24px;
            color:#303030;
            font-size:12px;
            text-align: left;
            font-weight: bold;
        }
        .tab_msg_group{
            margin-top:16px;
            .msg_offers{
                color:#303030;
                font-size:12px;
                padding:3px 8px;
                background:#e9e9e9;
                border-radius: 5px;
            }
            .msg_price{
                font-size:12px;
                color:#777;
                >span{
                    color:#303030;
                   
                }
            }
        }
    }
}
</style>